// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}



.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}